import React from 'react'
import {Helmet} from 'react-helmet'

export const Head = ({title, description, favicon, appleTouchIcon}) => (
  <Helmet>
    <meta name="description" content={description} />
    <title>{title}</title>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
    <link rel="icon" sizes="32x32" href={favicon} />
  </Helmet>
)
