import React from 'react'
import {routeLocal} from '../..'
import {
  AiOutlinePlusSquare,
  BsFillPlusSquareFill,
  FaConnectdevelop,
  GiVortex,
  HiOutlineShieldCheck,
  HiShieldCheck,
  RiCoupon5Line,
} from 'react-icons/all'

export const ProductSwitch = props => {
  const {
    products = [],
    product = {},
    env = 'production',
    className = 'product-switch',
    icons = {
      'GHOST-USER': <AiOutlinePlusSquare />,
      'GHOST-ADMIN': <BsFillPlusSquareFill />,
      'SECRETONE-USER': <HiOutlineShieldCheck />,
      'SECRETONE-ADMIN': <HiShieldCheck />,
      'SAMBA-ADMIN': <RiCoupon5Line />,
      'VORTEX-USER': <FaConnectdevelop />,
      'VORTEX-ADMIN': <GiVortex />,
    },
  } = props

  const handleClick = e => {
    if (e.currentTarget.dataset.url)
      window.location = e.currentTarget.dataset.url
  }

  return (
    <div className={className}>
      <div className="projects-navigation ">
        <div>
          {products.map(i => {
            const url = env === 'production' ? i.url : routeLocal(i)
            return (
              <div
                data-url={i.project === product.project ? '' : url}
                onClick={handleClick}
                key={i.id + '-' + i.name}
                className={
                  'item' + (i.project === product.project ? ' active' : '')
                }>
                <div className="media">{icons[i.project + '-' + i.access]}</div>
                <div className="detail">
                  <p>{i.name}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
