import React from 'react'
import {MdClose} from 'react-icons/md'
// import {Custom404} from '../Custom404'

export const Modal = ({
  title = 'Modal',
  // show
  children,
  onClose = null,
  className = '',
  scrollable = false,
  ...props
}) => {
  const handleClose = () => {
    if (onClose) onClose()
    document.body.classList.remove('overflow-hidden')
    console.log('handleClose')
  }

  const escClose = e => {
    if (e.key === 'Escape') {
      handleClose()
      document.body.removeEventListener('keyup', escClose, false)
    }
  }

  document.body.addEventListener('keyup', escClose)

  return (
    <div className="modal-base">
      <div className={'modal ' + className}>
        <div className="modal-title">
          <h2>{title || '-'}</h2>
          <MdClose onClick={handleClose} />
        </div>
        <div className="modal-content ">{children}</div>
      </div>
    </div>
  )
}
