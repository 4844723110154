import React from 'react'
import {Head} from './Head'

export const Layout = ({
  title,
  description,
  favicon,
  appleTouchIcon,
  ...props
}) => {
  return (
    <React.Fragment>
      <Head
        title={title}
        description={description}
        favicon={favicon}
        appleTouchIcon={appleTouchIcon}
      />
      <div id="layout" className={props.className}>
        {props.children}
      </div>
    </React.Fragment>
  )
}
