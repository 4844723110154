import React from 'react'
import {GoVersions} from 'react-icons/all'

export const Version = ({version = '0.0', icon = <GoVersions />}) => {
  return (
    <span className="version">
      {icon}v{version}
    </span>
  )
}
