import React from 'react'

export const Page = props => {
  return (
    <div
      id="page"
      className={props.className}
      data-spy="scroll"
      data-target="#header"
      data-offset="0">
      {props.children}
    </div>
  )
}
