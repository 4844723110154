export const routeLocal = ({project, access}) => {
  const localMap = {
    'GHOST-USER': 'http://localhost:3340',
    'GHOST-ADMIN': 'http://localhost:3350',
    'SECRETONE-USER': 'http://localhost:3341',
    'SECRETONE-ADMIN': 'http://localhost:3351',
    'SAMBA-ADMIN': 'http://localhost:3352',
    'VORTEX-USER': 'http://localhost:3343',
    'VORTEX-ADMIN': 'http://localhost:3353',
  }
  return localMap[`${project}-${access}`] || localMap['GHOST-USER']
}
