export class CustomAPIError extends Error {
  constructor(res) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(res)
    console.log(res)
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomAPIError)
    }
    res.error = res.error || {}
    this.name = 'CustomError'
    this.code = res.error.code || res.code
    this.message =
      res.error.exception_message || res.error.message || res.detail || res.Message || JSON.stringify(res)
    this.status = res.error.status || 'error' // error, warn, success, info
    // Custom debugging information
    this.date = new Date()
    // credential error redirection
    if (Number(this.code) === 401)
      window.location = process.env.REACT_APP_AUTH + '?credential_error'
  }
}
