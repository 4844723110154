import React from 'react'

export const Aside = ({title = 'Projects', ...props}) => {
  return (
    <div id="aside" className={props.className}>
      <div className="head">
        <p>{title}</p>
      </div>
      {props.children}
    </div>
  )
}
