import React from 'react'

export const Brand = ({onClick = () => {}, ...props}) => {
  return (
    <div className={'brand ' + props.className}>
      <button onClick={onClick}>
        <p>{props.children}</p>
      </button>
    </div>
  )
}
