import React, {useState, useRef} from 'react'
import {BsCaretDownFill, BsCaretDown} from 'react-icons/all'

export const DropDown = ({label = 'DropDown', icon, description, ...props}) => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false)
      document.removeEventListener('mousedown', handleClose, false)
    }
  }
  const handleToggle = () => {
    if (!isOpen) document.addEventListener('mousedown', handleClose, false)

    setIsOpen(!isOpen)
  }
  return (
    <div
      className={`dropdown ${props.className} ` + (isOpen && 'show')}
      onClick={handleToggle}
      ref={ref}>
      <label>
        {icon && <span className="icon">{icon}</span>}
        <span>
          {label}
          {description && <small>{description}</small>}
        </span>
        {isOpen ? <BsCaretDown /> : <BsCaretDownFill />}
      </label>
      <div className="dropdown-menu">
        {props.children || <a href="#">Sample Link</a>}
      </div>
    </div>
  )
}
