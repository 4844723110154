import React from 'react'
import {CgArrowsExchange} from 'react-icons/all'

export const EvetButton = ({me = {products: []}}) => {
  const handleClose = e => {
    if (!document.getElementById('aside').contains(e.target)) {
      document.getElementById('layout').classList.remove('show')
      document.removeEventListener('mousedown', handleClose, false)
    }
  }
  const toggleMenu = e => {
    document.getElementById('layout').classList.toggle('show')
    setTimeout(() => {
      document.addEventListener('mousedown', handleClose, false)
    }, 280)
  }
  const handleHome = () => {
    location.href = '/'
  }
  return (
    <React.Fragment>
      {me.products.length > 1 ? (
        <button onClick={toggleMenu} className="evet-button">
          <div className="logo" />
          <div className="icon ">
            <CgArrowsExchange />
          </div>
        </button>
      ) : (
        <button onClick={handleHome} className="evet-button">
          <div className="logo" />
        </button>
      )}
    </React.Fragment>
  )
}
