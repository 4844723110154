import cookie from 'react-cookies'
import jwtDecode from 'jwt-decode'
import FileSaver from 'file-saver'
import {qs} from './QueryString'
import {CustomAPIError} from './CustomAPIError'

export class request {
  constructor({
    api_url: apiUrl,
    cookie_domain: cookieDomain,
    refresh_token_url: refreshTokenUrl,
  }) {
    this.API_URL = apiUrl
    this.COOKIE_DOMAIN = cookieDomain
    this.REFRESH_TOKEN_URL = refreshTokenUrl
  }

  get token() {
    const token = cookie.load('access')
    return token || this.refreshToken().then()
  }
  //
  // get access() {
  //   return cookie.load('access')
  // }

  refreshToken = async () => {
    const {refresh: token, remember} = cookie.loadAll()
    if (token) {
      return await fetch(this.REFRESH_TOKEN_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({token}),
      })
        .then(async res => await res.json())
        .then(res => {
          this.setCookies({...res, remember})
          return res.access
        })
        .catch(error => {
          this.deleteCookies()
          console.log({error})
        })
    } else {
      console.log('There is no refresh token!')
      this.deleteCookies()
      return false
    }
  }

  deleteCookies = () => {
    localStorage.removeItem('location')
    localStorage.removeItem('me')
    // localStorage.clear();
    cookie.save('access', '', {path: '/', domain: this.COOKIE_DOMAIN})
    cookie.save('refresh', '', {path: '/', domain: this.COOKIE_DOMAIN})
    cookie.save('remember', '', {path: '/', domain: this.COOKIE_DOMAIN})
    cookie.remove('access', {path: '/', domain: this.COOKIE_DOMAIN})
    cookie.remove('refresh', {path: '/', domain: this.COOKIE_DOMAIN})
    cookie.remove('remember', {path: '/', domain: this.COOKIE_DOMAIN})
    cookie.remove('access', {path: '/'})
    cookie.remove('refresh', {path: '/'})
    cookie.remove('remember', {path: '/'})
  }

  setCookies = ({access, refresh = null, remember = false}) => {
    const {max_age: accessExp} = jwtDecode(access)
    if (access)
      cookie.save('access', access, {
        path: '/',
        maxAge: accessExp,
        domain: this.COOKIE_DOMAIN,
      })
    if (refresh) {
      const {max_age: refreshExp} = jwtDecode(refresh)
      cookie.save('refresh', refresh, {
        path: '/',
        maxAge: refreshExp,
        domain: this.COOKIE_DOMAIN,
      })
      cookie.save('remember', remember, {
        path: '/',
        maxAge: refreshExp,
        domain: this.COOKIE_DOMAIN,
      })
    }
  }

  get = async (endpoint, params = {}, secured = true) => {
    // if (params)
    //   Object.entries(params).forEach(([key, val]) => {
    //     if (typeof val === 'string' && val.indexOf(',') !== -1)
    //       params[key] = val.split(',')
    //   })
    params = params ? qs.stringify(params) : ''
    endpoint += params ? '?' + params : ''
    return await this.fetcher('GET', endpoint, null, secured)
  }

  post = async (endpoint, data, secured = true) => {
    return await this.fetcher('POST', endpoint, JSON.stringify(data), secured)
  }

  patch = async (endpoint, data, secured = true) => {
    return await this.fetcher('PATCH', endpoint, JSON.stringify(data), secured)
  }

  put = async (endpoint, data, secured = true) => {
    return await this.fetcher('PUT', endpoint, JSON.stringify(data), secured)
  }

  delete = async (endpoint, data, secured = true) => {
    return await this.fetcher('DELETE', endpoint, JSON.stringify(data), secured)
  }

  download = async (
    endpoint,
    params = {},
    secured = true,
    contentType = 'text/csv',
    fileName = 'report'
  ) => {
    params = params ? qs.stringify(params) : ''
    endpoint += params ? '?' + params : ''
    const response = await this.fetcher('GET', endpoint, null, secured)

    await fetch(response.data, {
      method: 'GET',
      headers: {
        'Content-Type': contentType,
        // no auth header
      },
      mode: 'cors',
      responseType: 'blob',
    })
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, fileName + '.csv'))
  }

  fetcher = async (method, url, body = null, secured = null) => {
    const token = secured ? await this.token : null
    if (secured && !token) return false
    const headers = {
      'Content-Type': 'application/json',
    }
    if (token) headers.Authorization = 'Bearer ' + token
    const response = await fetch(this.API_URL + url, {
      method: method || 'GET',
      headers,
      mode: 'cors',
      body,
    })
    const result = await response.json()
    if (response.status <= 201) return result || true
    throw new CustomAPIError({code: response.status, ...result})
  }
}
